<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách combo</h3>
      </div>
      <div class="card-title">
        <router-link
            class="font-weight-bold font-size-3  btn btn-success"
            :to="{ name: 'promotion-create' }"
        >Thêm mới
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <!--begin::Example-->
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <el-input placeholder="Tên mã khuyến mãi" v-model="query.keyword"></el-input>
          </div>
          <!--end::Form-->
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <el-select v-model="query.status" filterable class="w-100" placeholder="Trạng thái" clearable>
              <el-option
                  v-for="item in status"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                <span style="float: left">{{ item.label }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <el-select filterable clearable @input="handleQueryCenter"
                       class="w-100" placeholder="Trung tâm" v-model="query.center_id"
            >
              <el-option
                  v-for="item in centers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <el-select v-model="query.branch_id" filterable class="w-100" placeholder="Chi nhánh" clearable>
              <el-option
                  v-for="item in branches"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <date-picker valueType="format" placeholder="Ngày bắt đầu" v-model="query.start_date"></date-picker>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <date-picker valueType="format" placeholder="Ngày kết thúc" v-model="query.end_date"></date-picker>
          </div>
        </div>
        <div class="col-md-2">
          <div class="d-flex justify-content-between">
            <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                    @click="searchData">
              <i class="fa fa-search"></i> Tìm kiếm
            </button>
            <button v-else type="button" disabled
                    class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
              Tìm kiếm
            </button>
          </div>
        </div>
      </div>

      <div class="example mb-10">
        <div class="example-preview table-responsive b-table-sticky-header">
          <table class="table table-vertical-center b-table">
            <thead class="thead-light">
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Mã khuyến mại</th>
              <th scope="col" class="min-w-200px">Trung tâm</th>
              <th scope="col" class="min-w-300px">Chi nhánh</th>
              <th scope="col">Giá trị</th>
              <th scope="col" class="min-w-120px">Số lượng mã</th>
              <th scope="col">Loại</th>
              <th scope="col" class="min-w-120px">Quà tặng</th>
              <th scope="col" class="min-w-120px">Ngày áp dụng</th>
              <th scope="col" class="min-w-120px">Ngày kết thúc</th>
              <th scope="col" class="min-w-100px">Hành động</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item, index) in combos" :key="index">
              <td>{{ item.id }}</td>
              <td class="text-compact"><span :title="item.gift">{{ item.name }}</span></td>
              <td>{{ item.center ? item.center.name : '' }}</td>
              <td>{{ getBranchName(item) }}</td>
              <td>{{ item.value | formatPrice }}</td>
              <td>{{ item.amount ? item.amount : 0 }}</td>
              <td>
                <span v-if="item.type == 1">Combo</span>
                <span v-else>Voucher</span>
              </td>
              <td class="text-compact"><span :title="item.gift">{{ item.gift }}</span></td>
              <td>{{ item.start_date | formatDate }}</td>
              <td>{{ item.end_date  | formatDate }}</td>
              <td>
                <router-link :to="{ name: 'promotion-edit', params: {id : item.number} }" title="Sửa"
                             class="btn btn-sm btn-icon mr-2 btn-outline-info"><i
                    class="fas fa-pen-nib"></i>
                </router-link>
                <a title="Xóa" @click="deletePromotion(item.number)" href="javascript:"
                   class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import Multiselect from 'vue-multiselect'
// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {DELETE_PROMOTION, GET_PROMOTIONS} from "../../../core/services/store/contract/promotions.module";
import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import {GET_CENTERS} from "../../../core/services/store/center/centers.module";

const _ = require('lodash');

export default {
  components: {Multiselect, DatePicker},
  name: "PromotionIndex",
  data() {
    return {
      is_disable_search: false,
      combos: [],
      status: [
        {
          value: '',
          label: 'Tất cả',
        },
        {
          value: 0,
          label: 'Đang sử dụng',
        },
        {
          value: 1,
          label: 'Hết hạn',
        },
        {
          value: 2,
          label: 'Chưa sử dụng',
        },
      ],
      centers: [],
      branches: [],
      branchOnce: [],
      // Paginate
      page: 1,
      last_page: 1,
      query: {
        keyword: '',
        status: '',
        center_id: '',
        branch_id: '',
        start_date: '',
        end_date: '',
      },
    }
  },
  mounted() {
    this.getList();
    this.getBranchesOnce();
    this.getCenters();
    this.getBranches();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý combo", route: 'promotion-index'},
      {title: "Danh sách combo"}
    ]);
  },
  methods: {
    getBranchName(item) {
      let arrBranchId = item.branches.split(",");
      let arrResult = [];
      arrBranchId.map((branch_id) => {
        this.branchOnce.map((branch => {
          if (parseInt(branch_id) == parseInt(branch.id)) {
            arrResult.push(branch.name);
          }
        }))
      })
      return arrResult.join(',')
    },
    getCenters() {
      this.$store.dispatch(GET_CENTERS, {
        limit: 1000
      }).then((res) => {
        if (!res.error) {
          console.log(res)
          this.centers = res.data.data;
        }
      });
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 1000,
        center_id: this.query.center_id
      }).then((res) => {
        if (!res.error) {
          this.branches = res.data.data;
        }
      });
    },
    getBranchesOnce() {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 10000
      }).then((res) => {
        if (!res.error) {
          this.branchOnce = res.data.data;
        }
      });
    },
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsUrl()
      this.callApiGetList()
    },
    pushParamsUrl() {
      this.$router.push({
        name: 'promotion-index',
        query: {
          page: this.page,
          keyword: this.query.keyword,
          status: this.query.status,
          center_id: this.query.center_id,
          branch_id: this.query.branch_id,
          start_date: this.query.start_date,
          end_date: this.query.end_date,
        }
      })
    },
    handleQueryCenter($event) {
      this.query.center_id = $event;
      this.query.branch_id = '';
      this.getBranches();
    },
    getList() {
      this.pullParamsUrl()
      this.callApiGetList()
    },
    pullParamsUrl() {
      this.page = parseInt(this.$route.query.page);
      this.query.keyword = this.$route.query.keyword;
      this.query.website = this.$route.query.website != undefined && this.$route.query.website != '' ? parseInt(this.$route.query.website) : '';
      this.query.type = this.$route.query.type;
      this.query.status = this.$route.query.status != undefined && this.$route.query.status != '' ? parseInt(this.$route.query.status) : '';
      this.query.created_at = this.$route.query.created_at;
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({path: '', query: {page: this.page}})
      this.callApiGetList()
    },
    callApiGetList() {
      this.$store.dispatch(GET_PROMOTIONS, {
        page: this.page,
        keyword: this.query.keyword,
        status: this.query.status,
        center_id: this.query.center_id,
        branch_id: this.query.branch_id,
        start_date: this.query.start_date,
        end_date: this.query.end_date,
      }).then((res) => {
        if (!res.data.error) {
          this.is_disable_search = false;
          this.combos = res.data.data;
          this.last_page = res.data.last_page;
        }
      });
    },
    deletePromotion(number) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Xóa", "", "success");
          this.$store.dispatch(DELETE_PROMOTION, {
            number: number
          }).then((res) => {
            if (res.error) {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'danger',
                solid: true
              });
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'success',
                solid: true
              });
              this.getList()
            }
          });
        }
      });
    },
  }
}
</script>
<style>
.mx-datepicker {
  width: 100% !important;
}

.multiselect__tags {
  padding: 3px 40px 0 9px !important;
  min-height: 38px !important;
}

.multiselect {
  min-height: 38px !important;
}

.mx-input {
  min-height: 38px !important;
}

.button-active {
  position: static;
  background: #49d746 !important;
  border: #49d746 !important;
  border-radius: 5px !important;
}

.button-not-active {
  position: static;
  background: #F64E60 !important;
  border: #F64E60 !important;
  border-radius: 5px !important;
}

.b-table-sticky-header > .table.b-table > thead > tr > th {
  background: #F3F6F9;
}

/*.text-compact {*/
/*  float: left;*/
/*  width: 200px;*/
/*  overflow: hidden;*/
/*  text-overflow: ellipsis;*/
/*  white-space: nowrap;*/
/*}*/
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
